import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import WsReducer from './app/ws/WsSlice';

import DataQualityReducer from './app/DataQualityStatus/DataQualitySlice';
import ProcessRunsReducer from './app/ProcessRuns/ProcessRunsSlice';
import DataRollCallReducer from './app/DataRollCall/DataRollCallSlice';
import PkceReducer from './app/pkce/PkceSlice';

export const store = configureStore({
  reducer: {


    dataQualityReducer: DataQualityReducer,
    processRunsReducer: ProcessRunsReducer,
    dataRollCallReducer: DataRollCallReducer,


    customizer: CustomizerReducer,
    wsReducer : WsReducer,
    pkceReducer: PkceReducer,
  }
});

export default store;
