import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { closeAlert } from "../../store/app/DataQualityStatus/DataQualitySlice";



const AlertProvider = () => {

    const dispatch = useDispatch();

    const alertValues = useSelector(
        (state) => state.dataQualityReducer.alertValues
      );
    
    const handleClose = () => {
        
            dispatch(closeAlert()); 
        
    };        

    useEffect(() => {
        if (alertValues.isOpen) {
            setTimeout(() => {
                handleClose();
            }, 10000);
        }
    }, [alertValues.isOpen]);

    return (
        <div
            style={{ position: "fixed", top: "20px", right: "20px", zIndex: "9999" }}
        >
            <Alert color={alertValues.type} isOpen={alertValues.isOpen} toggle={handleClose}>
                {alertValues.message}
            </Alert>
        </div>
    );
};

export default AlertProvider;