import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import AuthGuard from "../components/authGurad/AuthGuard";
import ProcessRuns from "../views/ProcessRuns";
import ApplicationLog from "../views/ApplicationLog";
import DataRollCall from "../views/DataRollCall";
import MyActivities from "../views/MyActivities";
import DettaglioSingolaVersione from "../views/DettaglioSingolaVersione";
import DataQualityIssueReport from "../views/DataQualityIssueReport";
import VersionListRollCall from "../views/VersionListRollCall";
import ProductTypeListRollCall from "../views/ProductTypeListRollCall";
import Atlan from "../views/Atlan";

/****Layouts*****/
const HomeLayout = Loadable(lazy(() => import("../layouts/HomeLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
//***** Pages ****/
const DataQualityStatus = Loadable(lazy(() => import("../views/DataQualityStatus")));
const ElencoVersioni = Loadable(lazy(() => import("../views/ElencoVersioni")));
const DettaglioVersione = Loadable(lazy(() => import("../views/DettaglioVersione")));
const Ws = Loadable(lazy(() => import("../views/Ws")));
/***** Auth Pages ****/
/***** Auth Pages ****/
const Err = Loadable(lazy(() => import("../views/auth/Error")));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const FormLoginSSO = Loadable(lazy(() => import('../components/forms/form_login/FormLoginSSO')));

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    // children: [
    //   { path: "/", element: <Navigate to="/home" /> },
    //   { path: "/home", exact: true, element: <DataQualityStatus />, name: 'Data Quality Status'},
    //   { path: "/versioni/:type", exact: true, element: <ElencoVersioni />, name: 'Elenco versioni' },
    //   { path: "/versioni/detail/:id", exact: true, element: <DettaglioVersione /> },
    //   { path: "*", element: <Navigate to="/auth/404" /> },
    // ],
    children: [
      { path: "/", element: <Navigate to="/home" />, breadcrumb:  ['Data Quality Status'], paths: ['/home'] },
  
      { path: "/home", exact: true, element: <DataQualityStatus />, breadcrumb: ['Data Quality Status'], group: "DataQualityStatus", paths: ['/home'] },
      
      { path: "/versioni", exact: true, element: <ElencoVersioni />, breadcrumb: ['Data Quality Status', 'Elenco versioni'],  group: "DataQualityStatus", paths: ['/home','/versioni'] },
      { path: "/versioni/detail/:versionId", exact: true, element: <DettaglioVersione />, breadcrumb: ['Data Quality Status', 'Elenco versioni', 'Dettaglio Data Quality'],  group: "DataQualityStatus", paths: ['/home','/versioni', '/versioni/detail/:versionId'] },
      { path: "/versioni/detail/single-version/:versionId", exact: true, element: <DettaglioSingolaVersione />, breadcrumb: ['Data Quality Status', 'Elenco versioni', 'Dettaglio Data Quality', 'Contenuto singola versione'],  group: "DataQualityStatus", paths: ['/home','/versioni', '/versioni/detail/:versionId', '/versioni/detail/single-version/:versionId'] },
      
      { path: "/process-runs", exact: true, element: <ProcessRuns />, breadcrumb: ['Process Runs'],  group: "ProcessRuns", paths: ['/process-runs'] },
      { path: "/process-runs/detail/application-log/:versionId", exact: true, element: <ApplicationLog />, breadcrumb: ['Process Runs', 'Application Log'],  group: "ProcessRuns", paths: ['/process-runs', '/process-runs/detail/application-log/:versionId'] },
      
      { path: "/data-roll-call", exact: true, element: <DataRollCall />, breadcrumb: ['Monitoraggio SLA'],  group: "DataRollCall", paths: ['/data-roll-call'] },
      { path: "/data-roll-call/detail/version-list-roll-call", exact: true, element: <VersionListRollCall />, breadcrumb: ['Monitoraggio SLA', 'Elenco Versioni SLA'],  group: "DataRollCall", paths: ['/data-roll-call', '/data-roll-call/detail/version-list-roll-call'] },
      { path: "/data-roll-call/detail/product-type-list-roll-call", exact: true, element: <ProductTypeListRollCall />, breadcrumb: ['Monitoraggio SLA', 'Lista Prodotti SLA'],  group: "DataRollCall", paths: ['/data-roll-call', '/data-roll-call/detail/product-type-list-roll-call'] },
      
      { path: "/my-activities", exact: true, element: <MyActivities />, breadcrumb: ['My Activities'],  group: "MyActivities", paths: ['/my-activities'] },
      
      { path: "/data-quality-issue-report", exact: true, element: <DataQualityIssueReport />, breadcrumb: ['Data Quality Issue Report'],  group: "DataQualityIssueReport", paths: ['/data-quality-issue-report'] },
      
      { path: "*", element: <Navigate to="/auth/404" /> },
      
      
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Err />, breadcrumb:  [] },
      { path: 'register', element: <Register />, breadcrumb:  [] },
      { path: 'login', element: <FormLoginSSO />, breadcrumb:  [] },
      { path: 'atlan', element: <Atlan />, breadcrumb:  [] },
      { path: '*', element: <Navigate to="/auth/404" />, breadcrumb:  [] },
    ],
  },
  // {
  //   path: "/ws",
  //   element: <BlankLayout />,
  //   children: [
  //     { path: "ws-:id", exact: true, element: <Ws id=":id" /> }
  //   ],
  // },
];


export default ThemeRoutes;

