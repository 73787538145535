import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ReactDOM from "react-dom";

import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../components/kendoComponents/columnMenu/ColumnMenu";
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { process } from "@progress/kendo-data-query";
import moment from "moment";

import DimensionsSelector from "../components/DataQuality/DimensionsSelector";
import OtherVersionSelector from "../components/DataQuality/OtherVersionSelector";
import KeysSelector from "../components/DataQuality/KeysSelector";
import { useNavigate, useParams } from "react-router-dom";
import { SetCurrentActiveTab } from "../store/customizer/CustomizerSlice";
import { useEffectOnChange } from "../components/hooks/CustomHooks";
import "../assets/scss/kendo-theme-custom.scss";
import { CustomCell } from "../components/kendoComponents/customCell/CustomCell";
import { DateCell } from "../components/kendoComponents/dateCell/DateCell";

import {
  fetchVersionInfo,
  fetchVersionDqList,
  fetchVersionValidationHistory,
  fetchPayload,
  fetchSummary,
  fetchSummaryStructure,
  setFilterDimensions,
  fetchSummaryCounts,
  fetchCompareDimension,
  setFilterOtherVersion,
  setFilterKeys,
  fetchKeyChanges,
  fetchPayloadStructure,
  getPayloadStructure,
  getSummaryStructure,
} from "../store/app/DataQualityStatus/DataQualitySlice";

const DettaglioSingolaVersione = () => {
  const dispatch = useDispatch();
  const { versionId } = useParams();
  const navigate = useNavigate();

  const versionInfo = useSelector(
    (state) => state.dataQualityReducer.versionInfo
  );
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );

  const filterDimensions = useSelector(
    (state) => state.dataQualityReducer.filterDimensions
  );
  const payload = useSelector(
    (state) => state.dataQualityReducer.payload || []
  );
  const summary = useSelector(
    (state) => state.dataQualityReducer.summary || []
  );
  const dimensions = useSelector(
    (state) => state.dataQualityReducer.dimensions
  );
  const payloadStructure = useSelector(
    (state) => state.dataQualityReducer.payloadStructure || []
  );
  const summaryStructure = useSelector(
    (state) => state.dataQualityReducer.summaryStructure || []
  );
  const summaryCounts = useSelector(
    (state) => state.dataQualityReducer.summaryCounts || []
  );

  const comparedVersions = useSelector(
    (state) => state.dataQualityReducer.comparedVersions || []
  );

  const keyChanges = useSelector(
    (state) => state.dataQualityReducer.keyChanges || []
  );

  const filterOtherVersion = useSelector(
    (state) => state.dataQualityReducer.filterOtherVersion
  );

  const filterKey = useSelector((state) => state.dataQualityReducer.filterKeys);

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const gridContent = document?.querySelector(".k-grid-content");

  const [dataStatePayload, setDataStatePayload] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataStateSummary, setDataStateSummary] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataStateComparedVersion, setDataStateComparedVersion] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataStateKeyChanges, setDataStateKeyChanges] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResultPayload, setDataResultPayload] = useState(
    process(payload, dataStatePayload)
  );

  const [dataResultSummary, setDataResultSummary] = useState(
    process(summary, dataStateSummary)
  );

  const [dataResultComparedVersion, setDataResultComparedVersion] = useState(
    process(comparedVersions, dataStateComparedVersion)
  );

  const [dataResultkeyChanges, setDataResultkeyChanges] = useState(
    process(comparedVersions, dataStateComparedVersion)
  );

  useEffect(() => {
    dispatch(fetchVersionInfo(versionId));
    dispatch(fetchVersionDqList(versionId));
    dispatch(fetchVersionValidationHistory(versionId));
    dispatch(fetchPayloadStructure(versionId));

    return () => {
      dispatch(setFilterDimensions([]));
      dispatch(SetCurrentActiveTab("1"));
      dispatch(setFilterOtherVersion({}));
      dispatch(setFilterKeys({}));
      dispatch(getPayloadStructure([]))
      dispatch(getSummaryStructure([]))
    };
  }, [navigate, refreshCount]);

  useEffectOnChange(() => {
    if (
      dimensions.length > 0 &&
      (versionInfo.class_name != "Map" && versionInfo.class_name != "Dimension")
    ) {
      dispatch(setFilterDimensions(dimensions.map((item) => item.id)));
      dispatch(fetchSummaryStructure(versionId, dimensions));
    }
  }, [dimensions]);
  
  useEffectOnChange(() => {
    if (payloadStructure.length > 0) {
      dispatch(fetchPayload(versionId));
    }
  }, [payloadStructure]);

  useEffectOnChange(() => {
    if (summaryStructure.length > 0 && filterDimensions.length > 0) {
      dispatch(fetchSummary(versionId, filterDimensions));
      dispatch(fetchSummaryCounts(versionId));
    }
  }, [summaryStructure, filterDimensions]);

  useEffect(() => {
    setDataResultPayload(process(payload, dataStatePayload));
  }, [payload]);

  useEffect(() => {
    setDataResultSummary(process(summary, dataStateSummary));
  }, [summary]);

  useEffectOnChange(() => {
    if (
      (versionInfo.class_name == "Map" ||
        versionInfo.class_name == "Dimension") &&
      filterOtherVersion.version_id
    ) {
      dispatch(fetchCompareDimension(versionId, filterOtherVersion.version_id));
    }
  }, [filterOtherVersion]);

  useEffectOnChange(() => {
    if (
      (versionInfo.class_name == "Map" ||
        versionInfo.class_name == "Dimension") &&
      filterKey.obj
    )
      dispatch(fetchKeyChanges(versionId, filterKey));
  }, [filterKey]);

  useEffect(() => {
    setDataResultComparedVersion(
      process(comparedVersions, dataStateComparedVersion)
    );
  }, [comparedVersions]);

  useEffect(() => {
    setDataResultkeyChanges(process(keyChanges, dataStateKeyChanges));
  }, [keyChanges]);

  const mapGridPayload = (structure, index) => (
    <Column
      {...ColumnProps(structure.id, dataStatePayload)}
      key={index}
      title={structure.id}
      columnMenu={ColumnMenu}
      cell={(props) => CustomCell(props, structure.description)}
      width={150}
    />
  );

  const mapGridSummary = (structure, index) => (
    <Column
      {...ColumnProps(structure.id, dataStateSummary)}
      key={index}
      title={structure.id}
      cell={(props) => CustomCell(props, structure.description)}
      width={150}
      
    />
  );

  const currentActiveTab = useSelector(
    (state) => state.customizer.currentActiveTab
  );

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) dispatch(SetCurrentActiveTab(tab));
  };

  return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 py-2">
          <Col>
            <Row className="g-6">
              <Col md="auto">
                <Label for="categoryName">ID versione</Label>
                <p className="pt-0 form-control-plaintext">
                  {versionInfo.version_id}
                </p>
              </Col>
              <Col md="auto">
                <Label for="categoryName">Data Product</Label>
                <p className="pt-0 form-control-plaintext hv-75" role="button">
                  <span
                    className="px-2 bg-medium-primary border border-primary rounded"
                    onClick={(event) => {
                      window.open(
                        process.env.REACT_APP_ATLAN_PRE +
                          versionInfo.product_type_handle +
                          process.env.REACT_APP_ATLAN_POST,
                        "_blank"
                      );
                    }}
                  >
                    {versionInfo.product_name}
                  </span>
                </p>
              </Col>
              <Col md="auto">
                <Label for="categoryName">Data riferimento</Label>
                <p className="pt-0 form-control-plaintext">
                  {moment(versionInfo.reference_date).format("DD/MM/YYYY")}
                </p>
              </Col>
              <Col md="auto">
                <Label for="categoryName">Finalità</Label>
                <p className="pt-0 form-control-plaintext">
                  {versionInfo.purpose}
                </p>
              </Col>
              <Col md="auto">
                <Label for="categoryName">Tipo validazione</Label>
                <p className="pt-0 form-control-plaintext">
                  {versionInfo.is_manual_validation ? "Manuale" : "Automatica"}
                </p>
              </Col>
              <Col md="auto">
                <Label for="categoryName">Stato versione</Label>
                <p className="pt-0 form-control-plaintext">
                  {versionInfo.status_name} - {versionInfo.ext_status_name}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative">
        <Row className="row mt-2">
          <Col>
            <Nav pills>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Dettaglio
                </NavLink>
              </NavItem>
              {(versionInfo.class_name != "Map" &&
                versionInfo.class_name != "Dimension") && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "2",
                    })}
                    onClick={() => toggle("2")}
                  >
                    Sommario
                  </NavLink>
                </NavItem>
              )}
              {(versionInfo.class_name == "Map" ||
                versionInfo.class_name == "Dimension") && (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "3",
                      })}
                      onClick={() => toggle("3")}
                    >
                      Confronto
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === "4",
                      })}
                      onClick={() => toggle("4")}
                    >
                      Storico
                    </NavLink>
                  </NavItem>
                </>
              )}
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane
                className="grid_task_container_tabs_fixed border border-top-0"
                tabId="1"
              >
                <div
                  className="position-relative"
                  style={{ height: "calc(100% - 70px)" }}
                >
                  <div className="position-absolute w-100 h-100 top-0 start-0">
                    <Grid
                      style={{ height: "100%" }}
                      data={dataResultPayload}
                      {...dataStatePayload}
                      sortable={true}
                      total={dataResultPayload?.length}
                      groupable={true}
                      pager={(props) => GridPager(props, payload, "payload")} //Rimosso finche non trobiamo una soluzione per l'export di tutti i dati
                      onDataStateChange={(e) => {
                        setDataStatePayload(e.dataState);
                        setDataResultPayload(process(payload, e.dataState));
                      }}
                      pageable={true}
                    >
                      {payloadStructure?.map(mapGridPayload)}
                    
                    </Grid>
                  </div>
                </div>
              </TabPane>

              {(versionInfo.class_name != "Map" &&
                versionInfo.class_name != "Dimension") && (
                <TabPane
                  className="grid_task_container_tabs_fixed border border-top-0"
                  tabId="2"
                >
                  <Row className="mx-0 bg-light-primary">
                    <Col md="auto" className="ms-auto">
                      <Row className="align-iems-center my-3 pe-3">
                        <Col md="auto">
                          <Label for="categoryName">Dimensioni</Label>
                        </Col>
                        <Col>
                          <DimensionsSelector />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    className="position-relative"
                    style={{ height: "calc(100% - 70px)" }}
                  >
                    <div className="position-absolute w-100 h-100 top-0 start-0">
                      <Grid
                        style={{ height: "100%" }}
                        data={dataResultSummary}
                        {...dataStateSummary}
                        pageable={true}
                        total={dataResultSummary?.length}
                        pager={(props) => GridPager(props, summary)} //Rimosso finche non trobiamo una soluzione per l'export di tutti i dati
                        onDataStateChange={(e) => {
                          setDataStateSummary(e.dataState);
                          setDataResultSummary(process(summary, e.dataState));
                        }}
                      >
                        {summaryStructure?.map(mapGridSummary)}
                        <Column />
                      </Grid>
                    </div>
                  </div>
                </TabPane>
              )}

              {(versionInfo.class_name == "Map" ||
                versionInfo.class_name == "Dimension") && (
                <>
                  <TabPane
                    className="grid_task_container_tabs_fixed border border-top-0"
                    tabId="3"
                  >
                    <Row className="mx-0 bg-light-primary">
                      <Col>
                        <Row className="align-items-stretch mt-3 pe-3"></Row>
                      </Col>
                      <Col md="4" className="ms-auto">
                        <Row className="align-items-center my-3 pe-3">
                          <Col md="auto">
                            <Label for="categoryName">Confronta con Versione </Label>
                          </Col>
                          <Col>
                            <OtherVersionSelector />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      className="position-relative"
                      style={{ height: "calc(100% - 70px)" }}
                    >
                      <div className="position-absolute w-100 h-100 top-0 start-0">
                        <Grid
                          style={{ height: "100%" }}
                          data={dataResultComparedVersion}
                          {...dataStateComparedVersion}
                          pageable={{ buttonCount: 8 }}
                          total={dataResultComparedVersion?.length}
                          pager={(props) => GridPager(props, comparedVersions)}
                          onDataStateChange={(e) => {
                            setDataStateComparedVersion(e.dataState);
                            setDataResultComparedVersion(
                              process(comparedVersions, e.dataState)
                            );
                          }}
                        >
                          <Column
                            {...ColumnProps(
                              "diff_type",
                              dataStateComparedVersion
                            )}
                            title="Tipo Differenza"
                          />
                          <Column
                            {...ColumnProps("key", dataStateComparedVersion)}
                            title="Chiave"
                          />
                          <Column
                            {...ColumnProps(
                              "latest_non_key_values",
                              dataStateComparedVersion
                            )}
                            title="Ultimi valori non chiave"
                          />{" "}
                          <Column
                            {...ColumnProps(
                              "previous_non_key_values",
                              dataStateComparedVersion
                            )}
                            title="Precedenti valori non chiave"
                          />
                        </Grid>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane
                    className="grid_task_container_tabs_fixed border border-top-0"
                    tabId="4"
                  >
                    <Row className="mx-0 bg-light-primary">
                      <Col>
                        <Row className="align-items-stretch mt-3 pe-3"></Row>
                      </Col>
                      <Col md="4" className="ms-auto">
                        <Row className="align-items-center my-3 pe-3">
                          <Col md="auto">
                            <Label for="categoryName">Key </Label>
                          </Col>
                          <Col>
                            <KeysSelector />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      className="position-relative"
                      style={{ height: "calc(100% - 70px)" }}
                    >
                      <div className="position-absolute w-100 h-100 top-0 start-0">
                        <Grid
                          style={{ height: "100%" }}
                          data={dataResultkeyChanges}
                          {...dataStateKeyChanges}
                          pageable={{ buttonCount: 8 }}
                          total={dataResultkeyChanges?.length}
                          pager={(props) => GridPager(props, keyChanges)}
                          onDataStateChange={(e) => {
                            setDataStateKeyChanges(e.dataState);
                            setDataResultkeyChanges(
                              process(keyChanges, e.dataState)
                            );
                          }}
                        >
                          <Column
                            {...ColumnProps("version_id", dataStateKeyChanges)}
                            title="version_id"
                          />
                          <Column
                            {...ColumnProps("change_type", dataStateKeyChanges)}
                            title="change_type"
                            
                          />
                          <Column
                            {...ColumnProps(
                              "non_key_values",
                              dataStateKeyChanges
                            )}
                            title="non_key_values"
                            
                          />
                          <Column
                            {...ColumnProps(
                              "reference_date",
                              dataStateKeyChanges
                            )}
                            cell={(props) => DateCell(props, false)}
                            title="reference_date"
                            
                          />
                        </Grid>
                      </div>
                    </div>
                  </TabPane>
                </>
              )}
            </TabContent>
          </Col>
        </Row>
        {isSpinnerVisible &&
          (gridContent
            ? ReactDOM.createPortal(loadingPanel, gridContent)
            : loadingPanel)}
      </Container>
    </>
  );
};

export default DettaglioSingolaVersione;
